import { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { CheckboxList, CheckboxListProps } from '@legacy-vfuk/core-checkbox-list';
import { TextAreaInputWithLabel, TextAreaInputWithLabelProps } from '@legacy-vfuk/core-text-area-input-with-label';
import { TextInputWithLabel, TextInputWithLabelProps } from '@legacy-vfuk/core-text-input-with-label';
import { InputContainer, InputLabel, InputStateText } from './Form.styles';
import { SelectInput, SelectInputProps } from '@legacy-vfuk/core-select-input';

import {
  FormEventChange,
  FormEvents,
  FormItem,
  FormProps,
  IFormInput,
  IFormState,
  IValidationRules,
  IValidationType,
  PartialFormItem,
} from './Form.types';
import RadioButtonList from '@legacy-vfuk/core-radio-button-list/dist/RadioButtonList';

import AddressPicker from '@legacy-vfuk/core-address-picker';
import { Icon } from '@legacy-vfuk/core-icon';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

const Form = ({
  formInputs,
  formValidation,
  formSubmit,
  onInputChange,
  onInputBlur,
  shouldHaveFullWidthOnMobile = true,
  customInputLabelSize,
  formTheme = 'white',
}: FormProps) => {
  const initialIFormState = formInputs.reduce((acc, input) => {
    acc[input.label] = {
      value: input.customValue || '',
      status: input.status === 'disabled' ? 'disabled' : undefined,
      statusMessage: '',
      required: input?.requiredField || false,
      valid: false,
    };
    return acc;
  }, {} as IFormState);

  const [form, setForm] = useState<IFormState>(initialIFormState);

  const handleForm = (e: FormEvents, values: PartialFormItem, customName?: string) => {
    const { name } = e.target;
    setForm(prev => ({
      ...prev,
      [name as keyof IFormState]: {
        ...prev[name as keyof IFormState],
        ...values,
      },
    }));
  };

  const handleInputChange = (e: FormEvents, customValue?: string | boolean, customName?: string) => {
    const { value } = e.target;
    if (e.type == 'change' && value != undefined && value != '') {
      handleValidChange(e, true);
    }
    if (onInputChange) onInputChange(e);
    handleForm(e, { value: customValue ?? value });
  };

  const handleStatusChange = (e: FormEvents, status: 'success' | 'error', statusMessage = '') => {
    handleForm(e, { status, statusMessage });
  };

  const handleValidChange = (e: FormEvents, valid: boolean) => {
    handleForm(e, { valid });
  };

  const customRegexValidation = (
    value: string,
    regex: RegExp,
    e: FormEvents,
    setStatus?: boolean,
    customErrorMessage?: string
  ) => {
    if (regex.test(value)) {
      if (setStatus) handleStatusChange(e, 'success');
      handleValidChange(e, true);
    } else {
      if (setStatus) handleStatusChange(e, 'error', customErrorMessage || 'Invalid format.');
      handleValidChange(e, false);
    }
  };

  const lenghtValidation = (
    value: string,
    size: number,
    e: FormEvents,
    setStatus?: boolean,
    customErrorMessage?: string,
    maxSize?: number
  ) => {
    if (value.length > size && (maxSize ? value.length <= maxSize : true)) {
      if (setStatus) handleStatusChange(e, 'success');

      handleValidChange(e, true);
    } else {
      if (setStatus)
        handleStatusChange(
          e,
          'error',
          customErrorMessage || `${e.target.name} should have more than ${size} characters.`
        );
      handleValidChange(e, false);
    }
  };

  const emailValidation = (email: string, e: FormEvents, setStatus?: boolean, customErrorMessage?: string) => {
    const isValidEmail = emailRegex.test(email);
    if (isValidEmail) {
      if (setStatus) handleStatusChange(e, 'success');
      handleValidChange(e, true);
    } else {
      if (setStatus) handleStatusChange(e, 'error', customErrorMessage || 'Invalid email format.');
      handleValidChange(e, false);
    }
  };
  const confirmEmailValidation = (
    email: string,
    confirmEmail: string,
    e: FormEvents,
    setStatus?: boolean,
    customErrorMessage?: string
  ) => {
    const isValidEmail = emailRegex.test(confirmEmail);
    if (isValidEmail && email === confirmEmail && confirmEmail !== '') {
      if (setStatus) handleStatusChange(e, 'success', '');
      handleValidChange(e, true);
    } else {
      if (setStatus) {
        let errorMessage = customErrorMessage || 'Email does not match above.';
        if (confirmEmail == '' || !isValidEmail) {
          errorMessage = 'Please enter a valid e-mail address';
        }
        handleStatusChange(e, 'error', errorMessage);
      }

      handleValidChange(e, false);
    }
  };
  const addressValidation = (value: string, e: FormEvents, setStatus?: boolean, customErrorMessage?: string) => {
    // Example of address validation (adjust based on actual criteria for valid addresses)
    const isValidAddress = value.trim().length > 10; // For example, valid address should have more than 10 characters.

    if (isValidAddress) {
      if (setStatus) handleStatusChange(e, 'success');
      handleValidChange(e, true);
    } else {
      const errorMessage = customErrorMessage || 'Invalid address format.';
      if (setStatus) handleStatusChange(e, 'error', errorMessage);
      handleValidChange(e, false);
    }
  };
  const phoneValidation = (
    phone: string,
    code: string | undefined,
    e: FormEvents,
    setStatus?: boolean,
    minLength: number = 6,
    maxLength: number = 9
  ) => {
    let isValidPhone = false;
    if (code) {
      const codeNumber = code.replace('+', '');
      const regex = new RegExp(
        `^\\+?${codeNumber}( )?[1-9]( )?[0-9]{${minLength - 1 - codeNumber.length},${
          maxLength - 1 - codeNumber.length
        }}$`
      );

      isValidPhone = regex.test(phone);
    }

    if (isValidPhone) {
      if (setStatus) handleStatusChange(e, 'success');
      handleValidChange(e, true);
    } else {
      if (setStatus) handleStatusChange(e, 'error', 'Invalid phone number format.');
      handleValidChange(e, false);
    }
  };
  const postCodeValidationIreland = (
    postCode: string,
    e: FormEvents,
    setStatus?: boolean,
    customErrorMessage?: string
  ) => {
    let isValidPostCode = false;

    // Regex pattern for Irish Eircode (e.g., D02 XY03)
    const irishPostCodePattern = /^[A-Za-z0-9]{3} ?[A-Za-z0-9]{4}$/;

    // Test the postal code
    isValidPostCode = irishPostCodePattern.test(postCode);
    if (isValidPostCode) {
      if (setStatus) handleStatusChange(e, 'success');
      handleValidChange(e, true);
    } else {
      if (setStatus) handleStatusChange(e, 'error', customErrorMessage || 'Invalid Eircode format.');
      handleValidChange(e, false);
    }
  };
  const checkboxValidation = (checked: boolean, formItem: FormItem, e: FormEvents) => {
    if (formItem.required) {
      if (checked === true) {
        handleValidChange(e, true);
      } else {
        handleValidChange(e, false);
      }
    } else {
      handleValidChange(e, true);
    }
  };

  const dateValidation = (
    value: string,
    formItem: FormItem,
    e: FormEvents,
    setStatus?: boolean,
    customErrorMessage?: string
  ) => {
    if (formItem.required) {
      if (value == 'Day' || value == 'Month' || value == 'Year') {
        handleValidChange(e, false);
        if (setStatus) handleStatusChange(e, 'error', customErrorMessage || '');
      } else {
        if (setStatus) handleStatusChange(e, 'success');
        handleValidChange(e, true);
      }
    } else {
      if (setStatus) handleStatusChange(e, 'success');
      handleValidChange(e, true);
    }
  };

  const handleInputBlur = (e: FormEvents, validationType: IValidationType, validationRules: IValidationRules) => {
    handleValidation(e, validationType, validationRules, true);
    handleValidationTriggers(e, validationRules);
    if (onInputBlur) onInputBlur(e);
    if (validationType === 'confirmEmail') {
      handleValidation(
        {
          target: {
            value: form['Email address'].value, // Get the current value of the "Email" field
            name: 'Email address',
          },
        } as FormEvents,
        'confirmEmail',
        validationRules,
        true
      );
    }
  };

  const handleValidationTriggers = (e: FormEvents, validationRules: IValidationRules) => {
    if (validationRules.triggerFieldsValidation) {
      validationRules.triggerFieldsValidation.forEach(field => {
        const target = formInputs.find(input => input.label === field);
        handleValidation(
          { target: { name: field, value: form[field].value } } as FormEvents,
          target?.validationType || 'length',
          validationRules
        );
      });
    }
  };

  const handleValidation = (
    e: FormEvents,
    validationType: IValidationType,
    validationRules: IValidationRules,
    setStatus: boolean = true
  ) => {
    const { validationSize = 3, validationMaxSize = 100, phoneValidationCode } = validationRules;
    const { value, name } = e.target;
    const { checked } = e.target as FormEventChange<HTMLInputElement>['target'];
    const input = formInputs.find(input => input.label === name);
    const customErrorMessage = input?.customErrorMessage;

    switch (validationType) {
      case 'length':
        lenghtValidation(value, validationSize, e, setStatus, customErrorMessage, validationMaxSize);
        if (
          (input?.name === 'Address' || input?.label === 'Address 1' || input?.label === 'Address 2') &&
          value.length >= validationSize
        ) {
          addressValidation(value, e, setStatus, customErrorMessage);
        }
        break;
      case 'email':
        emailValidation(value, e, setStatus, customErrorMessage);
        break;
      case 'confirmEmail':
        confirmEmailValidation(form['Email address'].value as string, value, e, setStatus, customErrorMessage);
        break;
      case 'phone':
        phoneValidation(value, phoneValidationCode, e, setStatus, validationSize, validationMaxSize);
        break;
      case 'postCode':
        postCodeValidationIreland(value, e, setStatus, customErrorMessage);
        break;
      case 'checkbox':
        checkboxValidation(checked, form[name], e);
        break;
      case 'date':
        dateValidation(value, form[name], e, setStatus, customErrorMessage);
        break;
      case 'regex':
        customRegexValidation(value, validationRules.regex || new RegExp(''), e, setStatus, customErrorMessage);
        break;
      default:
        if (form[name]?.required) {
          if (value !== '') {
            handleValidChange(e, true);
          } else {
            handleValidChange(e, false);
          }
        } else {
          handleValidChange(e, true);
        }
        break;
    }
  };

  const handleFormValidation = () => {
    if (form) {
      const requiredItems = Object.values(form).filter(item => item.required);

      if (requiredItems.length == 0) {
        return true;
      }
      const allValuesFilled = requiredItems.every(item => item.valid);

      return allValuesFilled;
    }

    return false;
  };

  const renderInput = (input: IFormInput) => {
    const targetFormInput = form[input.label];
    const commonProps = {
      key: `${input.label}-input`,
      id: `${input.label}Input`,
      state: input.status === 'disabled' ? 'disabled' : form[input.label]?.status,
      fieldWrapper: {
        helpText: input.helpText,
        label: `${input.label} ${targetFormInput?.required ? '*' : ''} `,
        stateText: targetFormInput?.statusMessage,
        width: (input.fullWidth && 'full') as TextInputWithLabelProps['fieldWrapper']['width'],
      },
    };

    switch (input.inputType) {
      case 'inputField': {
        const textInputProps: TextInputWithLabelProps = {
          ...commonProps,
          textInput: {
            id: `${input.label}Text`,
            name: input.label,
            placeholder: input.placeholder,
            value: targetFormInput?.value as string,
            onChange: e => handleInputChange(e, undefined),
            onBlur: e =>
              handleInputBlur(e, input.validationType, {
                validationSize: input.lengthValidationSize,
                phoneValidationCode: input.phoneValidationCode,
                validationMaxSize: input.lengthValidationMaxSize,
                regex: input.regex,
                triggerFieldsValidation: input.triggerFieldsValidation,
              }),
          },
        };

        return <TextInputWithLabel {...textInputProps} />;
      }
      case 'select': {
        const selectInputProps: SelectInputProps = {
          ...commonProps,
          id: `${input.label}Dropdown`,
          options: input.options || [],
          state: commonProps.state as SelectInputProps['state'],
          name: input.label,
          placeholder: input.placeholder,
          value: form[input.label].value as string,
          onChange: handleInputChange,
          onBlur: e =>
            handleInputBlur(e, input.validationType, {
              validationSize: input.lengthValidationSize,
              phoneValidationCode: input.phoneValidationCode,
              triggerFieldsValidation: input.triggerFieldsValidation,
            }),
        };

        return (
          <>
            <InputLabel formTheme={formTheme} labelType={input.labelType} customLabelSize={customInputLabelSize}>
              {input.label}
            </InputLabel>
            <SelectInput {...selectInputProps} />
            {commonProps.fieldWrapper.stateText && (
              <InputStateText state={commonProps.state as 'success' | 'error'}>
                <Icon name="error" group="state" size={1} />
                {commonProps.fieldWrapper.stateText}
              </InputStateText>
            )}
          </>
        );
      }
      case 'inputArea': {
        const textareaInputProps: TextAreaInputWithLabelProps = {
          ...commonProps,
          state: commonProps.state as TextAreaInputWithLabelProps['state'],
          textAreaInput: {
            id: `${input.label}Text`,
            rows: 3,
            name: input.label,
            placeholder: input.placeholder,
            value: form[input.label].value as string,
            onChange: handleInputChange,
            onBlur: e =>
              handleInputBlur(e, input.validationType, {
                validationSize: input.lengthValidationSize,
                validationMaxSize: input.lengthValidationMaxSize,
                regex: input.regex,
                triggerFieldsValidation: input.triggerFieldsValidation,
              }),
          },
        };

        return <TextAreaInputWithLabel {...textareaInputProps} />;
      }
      case 'checkbox': {
        const checkboxOptions = input.checkboxOptions?.map(option => {
          return { ...option, checked: form[input.label].value == option.value };
        });
        const checkboxProps: CheckboxListProps = {
          fieldWrapper: { label: '', showLabel: false },
          orientation: checkboxOptions ? 'horizontal' : 'vertical',
          checkboxes: {
            groupName: input.label,
            id: `${input.label}Checkbox`,
            onChange: (e: FormEventChange<HTMLInputElement>) => {
              const sameValue = form[input.label].value == e.currentTarget.value;
              if (sameValue) {
                handleInputChange(e, false);
                handleValidation(e, 'checkbox', {});
                return;
              }
              handleInputChange(e, checkboxOptions ? e.currentTarget.value : e.currentTarget.checked);
              handleValidation(e, 'checkbox', {
                triggerFieldsValidation: input.triggerFieldsValidation,
              });
            },
            items: checkboxOptions || [
              {
                label: input.label,
                value: 'agree',
                checked: form[input.label].value as boolean,
              },
            ],
          },
        };
        return <CheckboxList {...checkboxProps} />;
      }
      case 'radioList': {
        if (input.radioOptions) {
          input.radioOptions.forEach(option => {
            option.state = commonProps.state == 'disabled' ? 'disabled' : undefined;
          });
        }
        return (
          <RadioButtonList
            {...commonProps}
            state={commonProps.state as 'error' | 'disabled' | undefined}
            orientation="horizontal"
            radioButtons={{
              groupName: input.name,
              id: input.name + input.label,
              onChange: e => {
                handleInputChange(e, undefined);
              },
              items: input.radioOptions || [],
              checked: form[input.name]?.value as string,
            }}
          />
        );
      }
      case 'addressPicker': {
        return (
          <AddressPicker
            {...commonProps}
            id={input.label}
            state={commonProps.state as 'disabled' | undefined}
            searchInput={{
              state: targetFormInput?.status,
              placeholder: input.placeholder,
              label: input.label,
              value: form[input.label]?.value as string,
              onSearch: event => {
                if (onInputBlur) {
                  onInputBlur({
                    target: {
                      name: input.label,
                      value: form[input.label]?.value as string,
                    },
                  } as FormEvents);
                }
              },
              onChange: (e: FormEventChange<HTMLInputElement>) => {
                e.target.name = input.label;
                handleInputChange(e, undefined);
                handleValidation(e, input.validationType, {
                  validationSize: input.lengthValidationSize,
                  phoneValidationCode: input.phoneValidationCode,
                  validationMaxSize: input.lengthValidationMaxSize,
                  regex: input.regex,
                  triggerFieldsValidation: input.triggerFieldsValidation,
                });
              },
              onClear: () => {
                handleInputChange({
                  target: {
                    name: input.label,
                    value: '',
                  },
                } as FormEvents);
              },
              helpText: input.helpText,
              stateText: targetFormInput?.statusMessage,
            }}
            selectInput={{
              label: input.label,
              value: form[`address-select-${input.label}`]?.value as string,
              onSelect: handleInputChange,
              options: input.options || [],
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    if (formValidation) {
      formValidation(handleFormValidation());
    }

    if (formSubmit) {
      formSubmit(form);
    }
  }, [form]);

  const getStatus = (
    inputStatus: string | undefined,
    prevFormStatus: string | undefined
  ): 'error' | 'disabled' | 'success' | undefined => {
    if (inputStatus === 'disabled') {
      return 'disabled';
    } else if (inputStatus === 'error' && prevFormStatus !== 'success') {
      return 'error';
    } else if (prevFormStatus === 'disabled') {
      return undefined;
    } else {
      return prevFormStatus as 'error' | 'disabled' | 'success' | undefined;
    }
  };

  useEffect(() => {
    formInputs.forEach(input => {
      let shouldSetCustomValue = input.customValue !== undefined && input.inputType !== 'checkbox';
      if (input.customValue == 'unsetCheckboxValue') {
        shouldSetCustomValue = true;
        input.customValue = '';
      }
      setForm(prevForm => ({
        ...prevForm,
        [input.label]: {
          ...prevForm[input.label],
          hidden: input.hidden,
          ...(shouldSetCustomValue ? { value: input.customValue } : {}),
          status: getStatus(input.status, prevForm[input.label]?.status),
          required: input.requiredField,
          options: input.options,
          ...(input.triggerError && input.status !== 'success' && input.customErrorMessage
            ? { statusMessage: prevForm[input.label]?.statusMessage || input.customErrorMessage }
            : {}),
        },
      }));
      if (input.triggerError && input.status !== 'success' && input.customErrorMessage) {
        input.resetTriggerError?.();
      }
      if (input.customValue !== undefined) {
        handleValidation(
          {
            target: {
              value: typeof input.customValue == 'string' && input.customValue,
              name: input.label,
              checked: typeof input.customValue == 'boolean' && input.customValue,
            },
          } as FormEvents,
          input.validationType,
          {
            phoneValidationCode: input.phoneValidationCode,
            validationSize: input.lengthValidationSize,
            validationMaxSize: input.lengthValidationMaxSize,
            regex: input.regex,
          },
          input.inputType === 'addressPicker' ? true : false
        );
      }
    });
  }, [formInputs]);

  return (
    <>
      {formInputs.map(input => (
        <InputContainer
          fullWidth={input.fullWidth}
          formTheme={formTheme}
          labelType={input.labelType}
          customWidth={input.customWidth}
          disabled={input.status === 'disabled'}
          hidden={input.hidden}
          fullWidthOnMobile={shouldHaveFullWidthOnMobile}
          customLabelSize={input.customLabelSize || customInputLabelSize}
          customFontSize={input.customFontSize}
        >
          {renderInput(input)}
        </InputContainer>
      ))}
    </>
  );
};

export default Form;
