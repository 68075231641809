import { useState, useEffect, useContext } from 'react';
import { FormEvents, IFormInput, FormEventChange } from '@/components/pageBuilder/Elements/Form/Form.types';
import axios from 'axios';
import { getAddress, getCounties, County, GetAddressType, GetAddressResponse } from '@/services/address.services';
import { generateDays } from './SelectOptions';
import { FormStateContext } from './FormContext';
import { getUserRegistrationDropdownValues, UserRegisterDropdownValues } from '@/services/user.services';
import { useModalContext } from '@/hooks/modal/useModal';
import { addressPickerEircodeName } from './FormConfigs/ContactFormConfig';

const addressName = 'address';
const eircodeName = 'eircodeOption';

const customAddressNames = ['address1', 'address2', 'postcode', 'city', 'stateOrProvince', 'countryCode'];
const companyRegistrationNumberInputName = 'companyRegistrationNumber';

export const useFormHandler = (formType: 'contact' | 'company' | 'billing') => {
  const context = useContext(FormStateContext);
  const { setErrorInfo, toggleModal } = useModalContext();

  if (!context) {
    throw new Error('useFormHandler must be used within a FormStateProvider');
  }

  const { contactForm, companyForm, billingForm, setContactForm, setCompanyForm, setBillingForm } = context;

  const getInitialFormConfig = () => {
    switch (formType) {
      case 'company':
        return companyForm;
      case 'contact':
        return contactForm;
      case 'billing':
        return billingForm;
      default:
        return contactForm;
    }
  };

  const [form, setForm] = useState<IFormInput[]>(getInitialFormConfig());
  let currentForm = form;
  const [year, setYear] = useState<string>();
  const [month, setMonth] = useState<string>();
  const [addressOption, setAddressOption] = useState<GetAddressType>();
  const [addressSearchValue, setAddressSearchValue] = useState<string>('');

  useEffect(() => {
    if (formType === 'contact') {
      setContactForm(form);
    } else if (formType === 'company') {
      setCompanyForm(form);
    } else if (formType === 'billing') {
      setBillingForm(form);
    }
  }, [form, formType, setContactForm, setCompanyForm, setBillingForm]);

  const handleInputMask = (name: string, value: string) => {
    let newValue;
    const input = form.find(input => input.name === name || input.label === name);
    if (!input) return;
    const dataType: IFormInput['dataType'] = input.dataType;
    switch (dataType) {
      case 'alpha':
        newValue = value.replace(/[^a-zA-Z]/g, '');
        break;
      case 'number':
        newValue = value.replace(/[^0-9]/g, '');
        break;
      case 'email':
        newValue = value.replace(/[^a-zA-Z0-9@._-]/g, '');
        break;
      case 'alphanum':
        newValue = value.replace(/[^a-zA-Z0-9]/g, '');
        break;
      case 'alphanum-space':
        newValue = value.replace(/[^a-zA-Z0-9 ]/g, '');
        break;
      default:
        newValue = value;
        break;
    }
    const updatedForm = currentForm.map(input => {
      if (input.name === name || input.label === name) {
        return {
          ...input,
          customValue: newValue,
        };
      }
      return input;
    });
    currentForm = updatedForm;
  };

  const setFieldsProperties = async (
    fieldNames: string | string[],
    properties: Partial<IFormInput>,
    shouldUpdateForm: boolean = false
  ) => {
    const fields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];
    const updatedForm = currentForm.map(input => {
      if (fields.includes(input.name)) {
        return {
          ...input,
          ...properties,
        };
      }
      return input;
    });
    currentForm = updatedForm;

    if (shouldUpdateForm) {
      setForm(currentForm);
    }
  };

  const handleInputChange = (e: FormEvents) => {
    const { name, value } = e.target;
    handleInputMask(name, value);

    if (name === 'address-select-Search Your Address') {
      if (value === 'none') {
        setFieldsProperties(customAddressNames, { hidden: true, status: undefined }, false);
      } else {
        setAddressFields(JSON.parse(value) as GetAddressResponse);
        setFieldsProperties(customAddressNames, { hidden: false, status: 'disabled' }, false);
      }
    }

    if (name === `address-select-${addressPickerEircodeName}`) {
      if (value === 'none') {
        setFieldsProperties(customAddressNames, { hidden: true, status: undefined }, false);
      } else {
        setAddressFields(JSON.parse(value) as GetAddressResponse);
        setFieldsProperties(customAddressNames, { hidden: false, status: 'disabled' }, false);
      }
    }

    if (name === 'Enter your address manually') {
      const checked = (e.target as FormEventChange<HTMLInputElement>['target']).checked;

      setFieldsProperties(customAddressNames, { status: undefined }, false);
      if (!addressOption) {
        setFieldsProperties(addressName, { status: 'disabled' }, false);
      }
      if (checked) {
        cleanCustomAddressFields();
        setFieldsProperties('AddressOption', { status: 'disabled' }, false);
        setFieldsProperties(customAddressNames, { hidden: false, status: undefined }, false);
        setFieldsProperties([eircodeName, addressName], { hidden: true, options: [] }, false);
        setFieldsProperties(['sameAsContactCheckbox'], { customValue: 'unsetCheckboxValue' });
      } else {
        setFieldsProperties('AddressOption', { status: undefined }, false);
        if (addressOption != null) {
          setFieldsProperties(addressOption === 'POSTAL_CODE' ? eircodeName : addressName, { hidden: false }, false);
        }
        setFieldsProperties(customAddressNames, { hidden: true }, false);
      }
    } else if (name === 'Same as contact address') {
      const checked = (e.target as FormEventChange<HTMLInputElement>['target']).checked;
      if (checked) {
        //Uncheck enter your address manually
        setFieldsProperties('AddressOption', { status: 'disabled' }, false);
        setFieldsProperties(['addressCheckbox'], { customValue: 'unsetCheckboxValue' });
        setFieldsProperties([eircodeName, addressName], { hidden: true });
        setFieldsProperties(customAddressNames, { hidden: false, status: 'disabled' });
        copyAddressFromContactForm();
      } else {
        setFieldsProperties('AddressOption', { status: undefined }, false);
        cleanCustomAddressFields(true);
        setFieldsProperties(customAddressNames, { status: 'disabled', hidden: true });
      }
    } else if (name === 'Country') {
      fillCountiesOptions(value);
    } else if (name === 'year') {
      const selectedYear = value;
      setYear(selectedYear);
      if (month) {
        updateDays(selectedYear, month);
      }
    } else if (name === 'month') {
      const selectedMonth = value;
      setMonth(selectedMonth);
      updateDays(year || '2024', selectedMonth);
    } else if (name === 'AddressOption') {
      const addressOptionValue = value as GetAddressType;
      setAddressOption(addressOptionValue);
      if (addressOptionValue === 'POSTAL_CODE') {
        setFieldsProperties([addressName], { hidden: true });
        setFieldsProperties([eircodeName], { status: undefined, hidden: false, options: [] });
      } else {
        setFieldsProperties([addressName], { status: undefined, hidden: false, options: [] });
        setFieldsProperties([eircodeName], { hidden: true });
      }
      setFieldsProperties(customAddressNames, { hidden: true, status: undefined });
      setFieldsProperties(['addressCheckbox', 'sameAsContactCheckbox'], { customValue: 'unsetCheckboxValue' });
    }

    if (name === 'Business type') {
      if (value === 'Limited Company') {
        const updatedForm = currentForm.map(input => {
          if (input.name === companyRegistrationNumberInputName) {
            return {
              ...input,
              hidden: false,
              requiredField: true,
            };
          } else if (input.name === 'typeOfBusiness') {
            return {
              ...input,
              customValue: value,
            };
          }
          return input;
        });
        currentForm = updatedForm;
      } else if (value !== 'Limited Company') {
        const updatedForm = currentForm.map(input => {
          if (input.name === companyRegistrationNumberInputName) {
            return {
              ...input,
              hidden: true,
              requiredField: false,
            };
          } else if (input.name === 'typeOfBusiness') {
            return {
              ...input,
              customValue: value,
            };
          }
          return input;
        });
        currentForm = updatedForm;
      }
    }
    setForm(currentForm);
  };

  const handleInputBlur = async (e: FormEvents) => {
    const { name, value } = e.target;
    setForm(prevForm =>
      prevForm.map(input => (input.name === name || input.label === name ? { ...input, customValue: value } : input))
    );
    if (e.target.name === 'Search Your Address') {
      setFieldsProperties([addressName], { status: undefined, hidden: false, options: [] });
      const value: string = e.target.value;
      setAddressSearchValue(value);
      if (value.length > 3 && addressOption) {
        getAddressValues(value, addressOption);
      }
    } else if (e.target.name === addressPickerEircodeName) {
      setFieldsProperties([eircodeName], { status: undefined, hidden: false, options: [] });
      const value: string = e.target.value;
      setAddressSearchValue(value);
      if (value.length > 3 && addressOption) {
        getAddressValues(value, addressOption);
      }
    }
  };

  const updateDays = (selectedYear: string, selectedMonth: string) => {
    const days = generateDays(parseInt(selectedMonth), parseInt(selectedYear));
    const updatedForm = currentForm.map(input => {
      if (input.name === 'daySelect') {
        return { ...input, options: days };
      }
      return input;
    });
    currentForm = updatedForm;
  };

  const cleanCustomAddressFields = (enable: boolean = false) => {
    const updatedForm = currentForm.map(input => {
      if (customAddressNames.includes(input.name) && input.name !== 'stateOrProvince' && input.name !== 'countryCode') {
        return { ...input, customValue: '', status: enable ? undefined : input.status };
      }
      return input;
    });
    currentForm = updatedForm;
  };

  const getAddressValues = async (addressValue: string, addressType: GetAddressType) => {
    await setFieldsProperties([addressName, eircodeName], { status: 'disabled' }, true);

    const res = await getAddress(addressValue, addressType);

    await setFieldsProperties([addressName, eircodeName], { status: undefined }, true);

    if (!axios.isAxiosError(res) && res.status === 200) {
      const options = res.data.map(address => {
        return { text: address.address1, value: JSON.stringify(address) };
      });
      if (options.length > 1) {
        options.unshift({ text: 'Select an address', value: 'none' });
      }
      setFieldsProperties([addressName, eircodeName], { options: options }, true);
      if (options.length == 1) {
        setAddressFields(JSON.parse(options[0].value));
        setFieldsProperties(customAddressNames, { hidden: false, status: 'disabled' }, true);
      }
    } else {
      setFieldsProperties(
        [addressName, eircodeName],
        { customErrorMessage: 'No addresses found, please try again', customValue: '' },
        true
      );
    }
  };

  const setAddressFields = (address: GetAddressResponse) => {
    const updatedForm = currentForm.map(input => {
      if (customAddressNames.includes(input.name)) {
        const newValue = address[input.name as keyof GetAddressResponse];
        const hasNewOption = input.options && input.options.find(opt => opt.value === newValue);
        const newOptions = (input.options && [...input.options, { text: newValue, value: newValue }]) || [
          {
            text: newValue,
            value: newValue,
          },
        ];
        return {
          ...input,
          customValue: newValue,
          options: hasNewOption ? input.options : newOptions,
        };
      }
      return input;
    });
    currentForm = updatedForm;
    setForm(currentForm);
  };

  const fillCountiesOptions = async (countryCode: string) => {
    await setFieldsProperties('stateOrProvince', { status: 'disabled' });
    const res = await getCounties(countryCode);
    await setFieldsProperties('stateOrProvince', { status: undefined });

    if (!axios.isAxiosError(res) && res.status === 200) {
      const counties: County[] = res.data;
      const updatedForm = currentForm.map(input => {
        if (input.name === 'stateOrProvince' && counties.length > 0) {
          return {
            ...input,
            options: [
              ...counties.map(county => {
                return { text: county.name, value: county.name };
              }),
            ],
            customValue: counties[0].name,
          };
        }
        return input;
      });
      currentForm = updatedForm;
    }
    setForm(currentForm);
  };

  const fillCompanyDropdownValues = async () => {
    const res = await getUserRegistrationDropdownValues(setErrorInfo, toggleModal);

    if (res) {
      const businessTypes: UserRegisterDropdownValues['businessTypes'] = res.data.businessTypes;
      const companySizes: UserRegisterDropdownValues['companySizes'] = res.data.companySizes;
      const industryTypes: UserRegisterDropdownValues['industryTypes'] = res.data.industryTypes;

      const updatedForm = currentForm.map(input => {
        if (businessTypes.length > 0) {
          if (input.name === companyRegistrationNumberInputName && businessTypes[0].value === 'Limited Company') {
            return {
              ...input,
              hidden: false,
            };
          }
          if (input.name === 'typeOfBusiness') {
            return {
              ...input,
              options: [
                ...businessTypes.map(bt => {
                  return { text: bt.label, value: bt.value };
                }),
              ],
              customValue: businessTypes[0].value,
            };
          }
        }
        if (input.name === 'companySize' && companySizes.length > 0) {
          return {
            ...input,
            options: [
              ...companySizes.map(cs => {
                return { text: cs.label, value: cs.value };
              }),
            ],
            customValue: companySizes[0].value,
          };
        } else if (input.name === 'industryType' && industryTypes.length > 0) {
          return {
            ...input,
            options: [
              ...industryTypes.map(it => {
                return { text: it.label, value: it.value };
              }),
            ],
            customValue: industryTypes[0].value,
          };
        }
        return input;
      });
      currentForm = updatedForm;
      setForm(currentForm);
    }
  };

  const copyAddressFromContactForm = () => {
    const updatedForm = currentForm.map(input => {
      if (customAddressNames.includes(input.name)) {
        const contactInput = contactForm.find(contactInput => contactInput.name === input.name);
        return contactInput ? { ...input, customValue: contactInput.customValue } : input;
      }
      return input;
    });
    currentForm = updatedForm;
  };

  useEffect(() => {
    const countryInput = form.find(input => input.name === 'countryCode');
    if (countryInput && countryInput.options && countryInput.options.length > 0) {
      fillCountiesOptions(countryInput.options[0].value);
      const updatedForm = currentForm.map(input => {
        if (input.name === 'countryCode') {
          return { ...input, customValue: countryInput.options?.[0]?.value };
        }
        return input;
      });
      currentForm = updatedForm;
    }
    if (formType === 'company') {
      fillCompanyDropdownValues();
    }
    setForm(currentForm);
  }, []);

  return {
    form,
    handleInputChange,
    handleInputBlur,
    setForm,
  };
};
