import axios, { AxiosError } from 'axios';

import { UserSessionProps } from '@/store';
import { OPCOOptions, UserServiceHeaderOptions, UserServiceHeaders } from '@/types/Services.types';

import { api } from './auth.services';
import { InitialState, ModalTypes } from '@/hooks/modal/types';

export type UserRegistrationPayload = {
  user: {
    firstName: string;
    lastname: string;
    birthDate: string;
    email: string;
    phoneNumber: string;
    eircode: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    stateOrProvince: string;
  };
  company: {
    companyName: string;
    companySize: string;
    industryType: string;
    businessType: string;
    eircode: string;
    address1: string;
    address2: string;
    city: string;
    country: string;
    iban: string;
    registrationNumber?: string;
    stateOrProvince: string;
  };
};

export type UserRegisterDropdownValues = {
  businessTypes: [
    {
      label: string;
      value: string;
    }
  ];
  companySizes: [
    {
      label: string;
      value: string;
    }
  ];
  industryTypes: [
    {
      label: string;
      value: string;
    }
  ];
};

export const eligibility = async () => {
  const res = await api.get<Pick<UserSessionProps, 'accounts'>>(`/eligibility`).catch<AxiosError>(error => error);
  return res;
};

export const users = async (headerOptions: UserServiceHeaderOptions) => {
  const OPCO = process.env.GATSBY_OPCO;
  const { customerAccountId, companyName, barId, segment, customerId } = headerOptions;

  let headers: UserServiceHeaders = {};

  switch (OPCO) {
    case OPCOOptions.PORTUGAL:
      headers = {
        ...headers,
        'x-company-name': companyName,
        'x-customer-account-id': customerAccountId,
        'x-customer-id': customerId,
      };
      break;
    case OPCOOptions.IRELAND:
    case OPCOOptions.SHOWHOME:
    default:
      headers = {
        ...headers,
        'x-bar-id': barId,
        'x-segment': segment,
        'x-customer-id': customerId,
      };
      break;
  }

  const res = await api
    .get<Pick<UserSessionProps, 'accounts'>>(`/users`, {
      headers,
    })
    .catch<AxiosError>(error => error);
  return res;
};

export const registerUser = async (
  data: UserRegistrationPayload,
  setErrorInfo: (info: InitialState['errorInfo']) => void,
  toggleModal: (isModalOpen: InitialState['isModalOpen'], modalType?: ModalTypes) => void
) => {
  try {
    const res = await api.post('/register', data);
    return res;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.data && error.response?.data.errorCode == 'USERNAME_ALREADY_EXISTS') {
        return {
          status: 400,
          data: {
            errorCode: 'USERNAME_ALREADY_EXISTS',
          },
        };
      }
      setErrorInfo({
        statusType: error.response?.data?.error,
        statusCode: error.response?.data?.code || error.response?.status,
        requestUrl: 'users',
      });
      toggleModal(true, 'Error');
    }

    return false;
  }
};

export const getUserRegistrationDropdownValues = async (
  setErrorInfo: (info: InitialState['errorInfo']) => void,
  toggleModal: (isModalOpen: InitialState['isModalOpen'], modalType?: ModalTypes) => void
) => {
  try {
    const res = await api.get<UserRegisterDropdownValues>(`/dropdown-values/user-registration`);
    return res;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorInfo({
        statusType: error.response?.data?.error,
        statusCode: error.response?.data?.code || error.response?.status,
        requestUrl: 'users',
      });
      toggleModal(true, 'Error');
    }

    return false;
  }
};
