import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { Heading } from '@uk-source-web/heading';
import { Paragraph } from '@uk-source-web/paragraph';
import { deleteBasket, useAppDispatch, useAppSelector } from '@/store';
import { SWBasketProps } from './SWBasket.types';

import { ReactComponent as EmptyBasketIcon } from '../../../../images/icon/empty-basket.svg';

import { useModalContext } from '@/hooks/modal/useModal';
import {
  CreditVettingPayload,
  ProductOrderBasketProduct,
  creditVettingProductOrder,
  productOrder,
} from '@/services/product.services';

import BasketItem from './BasketItem';
import { PRODUCT_BUNDLE_TYPENAME, transformToItemOrder } from './SWBasket.helper';

import SWBasketStyles, {
  BasketButtonsInner,
  ContainerHeaderStyles,
  EmptyBasket,
  SWBasketContainer,
  SWBasketHeader,
  SWBasketSummary,
  SWBasketSummaryInner,
  WrapperBasketButtons,
} from './SWBasket.styles';
import handleTranslation from '@/helpers/handleTranslation';
import { BasketHorizontalStepper } from './BasketHorizontalStepper/BasketHorizontalStepper';

import usingOpcoUrl from '@/helpers/prefixHelper';
import BasketDiscount from './BasketDiscount/BasketDiscount';
import BasketStrip from './BasketStrip/BasketStrip';
import { checkForZeroOrNull } from '@/helpers/nullOrZero';
import { useDiscount } from '@/hooks/useDiscount';
import { Button } from '@uk-source-web/button';
import { OPCOOptions } from '@/types/Services.types';
import { CreditVettingForm, CreditVettingFormRef } from '../CreditVettingForm/CreditVettingForm';

const OPCO = process.env.GATSBY_OPCO;

const SWBasket = ({ name, continueShoppingButton, requestCallbackButton, goToCheckoutButton }: SWBasketProps) => {
  const { width } = useWindowSize();
  const { toggleModal, setErrorInfo } = useModalContext();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ userSession }) => userSession);
  const { userBasket } = useAppSelector(({ userBasket: { data } }) => ({
    userBasket: data,
  }));
  const [inlineNotificationVisible, setInlineNotificationVisible] = useState(false);

  const yourOrderSummary = handleTranslation('Your Order Summary', 'Carrinho de compras');
  const emptyBasket = handleTranslation('Your basket is empty', 'O seu carrinho está vazio');
  const emptyBasketSubtitle = handleTranslation(
    'The items you add will appear here',
    'Os itens que você adicionar aparecerão aqui'
  );
  const accountTrans = handleTranslation('Account', 'Conta');

  const [currentStep, setCurrentStep] = useState(1);

  const [disableContinueButton, setDisableContinueButton] = useState<boolean>(true);

  //for microsoft products
  const [microsoftAdditionalData, setMicrosoftAdditionalData] = useState<string>(() => {
    return localStorage.getItem('microsoftAdditionalData') || 'warn';
  });

  const setMicrosoftAdditionalDataState = (data: string) => {
    setMicrosoftAdditionalData(data);
  };

  //determine the initial state of continue purchase button and enable it even after quantity
  useEffect(() => {
    const hasMicrosoftProduct = userBasket.items.some(item => item.msftProduct);

    if (hasMicrosoftProduct && microsoftAdditionalData !== 'success') {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  }, [userBasket.items, microsoftAdditionalData]);

  const creditVettingFormRef = useRef<CreditVettingFormRef>(null);

  const handleContinueClick = () => {
    if (creditVettingFormRef.current) {
      creditVettingFormRef.current.submit();
    }
  };

  const postProductOrder = async (e: { preventDefault: () => void }) => {
    if (currentStep === 2) {
      handleContinueClick();
      return;
    }
    e.preventDefault();
    toggleModal(true, 'LoadingCheckout');

    if (
      user?.organizationId &&
      user?.userId &&
      userBasket &&
      userBasket.items.length > 0 &&
      !userBasket.items.find(item => !item.dxlInformation?.periods || !item.dxlInformation?.periods[0].uuid)
    ) {
      const products = userBasket.items.flatMap(item => {
        const { quantity } = item;
        if (
          item.__typename === PRODUCT_BUNDLE_TYPENAME &&
          item.productsFreeOfCharge &&
          item.productsFreeOfCharge.length > 0
        ) {
          return [
            transformToItemOrder(item, undefined),
            ...item.productsFreeOfCharge.map(subItem => transformToItemOrder(subItem, quantity)),
          ];
        } else if (item.__typename === PRODUCT_BUNDLE_TYPENAME && !item.productsFreeOfCharge) {
          return transformToItemOrder(item, undefined);
        }
        return transformToItemOrder(item, undefined);
      }) as ProductOrderBasketProduct[];

      const res = await productOrder(
        {
          basket: {
            organizationId: user?.organizationId,
            userId: user?.userId,
            // INFO: The order sent to the marketplace need to be checked if it's being calculated right, because here
            // we are just sending the prices to cost one, maybe we should send every unit price related to the product.
            products,
          },
        },
        user.csrfToken || '',
        setErrorInfo,
        toggleModal
      );
      toggleModal(false, 'LoadingCheckout');
      if (res?.data.needCreditVetting) {
        setDisableContinueButton(true);
        setCurrentStep(2);
      } else if (res?.data.externalId) {
        dispatch(deleteBasket());
        return usingOpcoUrl(`/new-order-flow-success-page?id=${res.data.id}&email=${res.data.userEmail}`, true);
      }
    }
  };

  const handleCreditVettingSubmit = async (payload: CreditVettingPayload) => {
    if (payload.bankStartDay == null || payload.businessStartDate == null) {
      return;
    }
    toggleModal(true, 'LoadingCheckout');
    const res = await creditVettingProductOrder(payload, user?.csrfToken || '', setErrorInfo, toggleModal, false);
    toggleModal(false, 'LoadingCheckout');

    if (res) {
      dispatch(deleteBasket());
      return usingOpcoUrl(`/new-order-flow-success-page?id=${res.data.externalId}&email=${res.data.userEmail}`, true);
    } else {
      setInlineNotificationVisible(true);
    }
  };

  const handleCreditVettingChange = (payload: CreditVettingPayload) => {
    if (
      payload.bankStartDay == null ||
      payload.bankStartDay == '' ||
      payload.businessStartDate == null ||
      payload.businessStartDate == ''
    ) {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  };

  const montlyCostValue = checkForZeroOrNull(userBasket.total?.monthly?.gross, 'NA');
  const upfrontCostValue = checkForZeroOrNull(userBasket.total?.upfront?.gross, 'NA');

  const { discountData, applyDiscount, loading } = useDiscount();

  const handleSetDisableContinueButton = useCallback((value: boolean | ((prevState: boolean) => boolean)) => {
    setDisableContinueButton(value);
  }, []);

  const BasketContent = () => {
    return (
      <SWBasketSummaryInner>
        <Heading level={2} size={2} weight={3} text={yourOrderSummary} />
        {userBasket.items.map(product => {
          if (product.__typename === PRODUCT_BUNDLE_TYPENAME) {
            const discountValue = discountData?.discountValue;
            const productDiscount = discountData?.itemDiscount?.find(item => item.productId === product.productId);
            const hasDiscount =
              (productDiscount?.productRecurringDiscount && productDiscount.productRecurringDiscount > 0) ||
              (productDiscount?.productUpfrontDiscount && productDiscount.productUpfrontDiscount > 0);
            const discountObj = useMemo(
              () => ({
                discountData: hasDiscount ? discountValue : undefined,
              }),
              [hasDiscount, discountValue]
            );

            return (
              <>
                <BasketItem
                  key={product.id}
                  {...product}
                  discountData={discountObj}
                  continueBtn={handleSetDisableContinueButton}
                  microsoftItemState={setMicrosoftAdditionalData}
                  microsoftState={microsoftAdditionalData}
                />
                {product?.productsFreeOfCharge?.map(productFreeOfCharge => (
                  <BasketItem
                    key={productFreeOfCharge.id}
                    {...productFreeOfCharge}
                    increaseDecreaseAct={false}
                    quantity={product.quantity}
                  />
                ))}
              </>
            );
          }
        })}
      </SWBasketSummaryInner>
    );
  };

  return (
    <SWBasketStyles>
      <SWBasketContainer {...ContainerHeaderStyles(width)}>
        <SWBasketHeader>
          <Heading level={3} justify={'center'} text={name} />
          <Paragraph size={2}>
            <span>{accountTrans}</span>
            <br />
            <b>{user?.selectedAccount?.customerAccountId}</b>
          </Paragraph>
        </SWBasketHeader>
      </SWBasketContainer>
      {userBasket?.items.length > 0 ? (
        <>
          <BasketHorizontalStepper currentStep={currentStep} />
          <SWBasketContainer backgroundColor="#F4F4F4">
            <SWBasketSummary>
              {currentStep === 1 && <BasketContent />}
              {currentStep === 2 && (
                <CreditVettingForm
                  ref={creditVettingFormRef}
                  description="Please validate bank registration details before proceeding to the next step"
                  __typename="ContentfulSectionCreditVettingForm"
                  id="credit-vetting-form-basket"
                  onSubmit={handleCreditVettingSubmit}
                  onChange={handleCreditVettingChange}
                  inlineNotificationVisible={inlineNotificationVisible}
                  inlineNotificationMessage="Credit vetting failed. Please select the correct information or contact sales agent."
                />
              )}
            </SWBasketSummary>
            <BasketDiscount shouldShowDiscountInput={currentStep === 1} />
          </SWBasketContainer>
          <BasketStrip
            continueShoppingButton={continueShoppingButton}
            requestCallbackButton={requestCallbackButton}
            goToCheckoutButton={goToCheckoutButton}
            postProductOrder={postProductOrder}
            disableContinueButton={disableContinueButton}
            hideContinueShoppingButton={currentStep === 2}
          />
        </>
      ) : (
        <SWBasketContainer backgroundColor="#F4F4F4">
          <SWBasketSummary>
            <EmptyBasket>
              <Heading size={2} level={3} weight={3}>
                {emptyBasket}
              </Heading>
              <Paragraph weight={1}>{emptyBasketSubtitle}</Paragraph>
              <WrapperBasketButtons>
                <BasketButtonsInner>
                  {OPCO !== OPCOOptions.PORTUGAL && (
                    <Button
                      text={continueShoppingButton.label}
                      appearance="primary"
                      href={continueShoppingButton.url}
                      data-selector="MS365-BundleConfig-OrderSummary-Next"
                    />
                  )}
                  {requestCallbackButton && (
                    <Button
                      text={requestCallbackButton.label}
                      appearance="alt1"
                      href={requestCallbackButton?.url}
                      data-selector="MS365-BundleConfig-OrderSummary-RequestCallback"
                    />
                  )}
                </BasketButtonsInner>
              </WrapperBasketButtons>
            </EmptyBasket>
          </SWBasketSummary>
        </SWBasketContainer>
      )}
    </SWBasketStyles>
  );
};

export default SWBasket;
