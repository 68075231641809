import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import Form from '@/components/pageBuilder/Elements/Form';
import { ContentWrapper, Title } from './RegisterForms.styles';
import { useFormHandler } from './useFormHandler';
import { IFormProps } from './RegisterForms.types';

type ContactDetailsRef = {
  showExistingEmailError: () => void;
};

export const ContactDetails = forwardRef<ContactDetailsRef, IFormProps>((props, ref) => {
  const { form, handleInputChange, handleInputBlur, setForm } = useFormHandler('contact');
  useImperativeHandle(
    ref,
    () => ({
      showExistingEmailError() {
        const emailInput = form.find(input => input.name === 'emailAddress');
        if (emailInput) {
          emailInput.customErrorMessage = 'This email is already in use. Please use another one.';
          emailInput.status = 'error';
          emailInput.triggerError = true;
          emailInput.resetTriggerError = resetTriggerError;
          setForm(prevForm => prevForm.map(input => (input.name === 'emailAddress' ? emailInput : input)));
        }
      },
    }),
    [form, setForm]
  );

  const resetTriggerError = () => {
    const emailInput = form.find(input => input.name === 'emailAddress');
    if (emailInput) {
      emailInput.triggerError = false;
      emailInput.customErrorMessage = '';
      emailInput.status = undefined;
      setForm(prevForm => prevForm.map(input => (input.name === 'emailAddress' ? emailInput : input)));
    }
  };

  return (
    <ContentWrapper>
      <Title formTheme={props.formTheme}>Contact details</Title>
      <Form
        customInputLabelSize="14px"
        formInputs={form}
        formValidation={isValid => {
          props.validCallback(isValid, 'contact');
        }}
        onInputChange={handleInputChange}
        onInputBlur={handleInputBlur}
        shouldHaveFullWidthOnMobile={true}
        formSubmit={form => props.onSubmit && props.onSubmit(form, 'contact')}
        formTheme={props.formTheme}
      />
    </ContentWrapper>
  );
});
