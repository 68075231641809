import { IFormInput } from '@/components/pageBuilder/Elements/Form/Form.types';

export const billingDetailsForm: IFormInput[] = [
  {
    inputType: 'inputField',
    name: 'bankName',
    label: 'Account holder’s name',
    fullWidth: true,
    placeholder: 'Jhon Doe',
    validationType: 'length',
    requiredField: true,
  },
  {
    inputType: 'inputField',
    name: 'iban',
    label: 'IBAN',
    fullWidth: true,
    requiredField: true,
    placeholder: 'IE XX XXXXXXXX XXXXXX XXXXXXXX',
    customErrorMessage: 'Invalid IBAN number',
    dataType: 'alphanum',
    validationType: 'regex',
    regex: /^(IE|GB)[A-Z0-9]{3,22}$/,
    helpText: 'Find your IBAN on a bank statement or within the account details in your banking app',
  },
];
