import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

interface BasketItemGridPricesProps {
  hasUpfrontPrice?: boolean;
}

 const BasketItemStyles = styled.div<{ hasFlag?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column; /* Ensures vertical stacking of elements */
  align-items: start; /* Horizontally centers elements */
  justify-content: start; /* Vertically centers elements */
  width: 100%;
  aspect-ratio: 10 / 1;
  background-color: #ffffff;
  padding: ${rem('21px')};
  border-radius: ${rem('6px')};
  margin-bottom: ${rem('20px')};
  gap: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    ${({ hasFlag }) =>
      hasFlag &&
      css`
        padding-top: ${rem('40px')};
      `}
  }
`;



export const BasketItemNormal = styled.div`
  display: flex;
  flex-direction:row ;
  justify-content: start;
  align-items: start;
  width: 90%;

`

 

// THUMBNAIL
export const BasketItemThumbWrapper = styled.div`


  display: flex;
  align-items: start;
  min-height: ${rem('100px')};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm - 1 }px) {
    align-items: start;
    
    
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    align-items: center;
    
  }
  


`;

export const BasketItemThumb = styled.div<{ hasThumbnail?: boolean }>`
  width: ${rem('50px')};
  height: ${rem('50px')};
  border-radius: ${rem('6px')};
  overflow: hidden;
  flex-shrink: 0;
  margin-right: ${rem('20px')};

  ${({ hasThumbnail }) =>
    !hasThumbnail &&
    css`
      background-color: #eaeaea;
    `}
`;

// ACCORDION
export const BasketAccordion = styled(Accordion)`
  && {
    width: 100%;
  }
`;
export const BasketAccordionItem = styled(AccordionItem)``;
export const BasketAccordionButton = styled(AccordionButton)``;

export const BasketAccordionButtonWrapper = styled.div<{ isExpanded: boolean }>`
  button {
    width: unset;
    padding: ${rem('2px')};

    #basket-accordion-details {
      text-decoration: underline;
      color: var(--mineShaft);
      @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        display: none;
      }
    }

    #basket-accordion-button {
      margin-left: ${rem('2px')};
      transition: 0.2s ease-out;
      transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'none')};
    }
  }
`;

export const BasketAccordionPanelWrapper = styled.div`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    order: 2;
  }
`;

export const BasketAccordionPanel = styled(AccordionPanel)`
  && {
    padding: ${rem('10px')} 0;
  }
`;

export const BasketItemInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    
  }
`;

export const BasketItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  
  
  width: 100%;
  max-width: 100%;
  height: 10vh;
  
  

  > h4 {
    flex-shrink: 0;
    max-width: 100%;
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    align-items: center;
    flex-direction: row;
    
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 8vh;
    
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 40%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 46%;
  }
`;

export const BasketItemContents = styled.div<BasketItemGridPricesProps>`
  display: flex;
  gap: 1rem;
  justify-content: ${({ hasUpfrontPrice }) =>
      hasUpfrontPrice
        ? `flex-end`
        : `space-between`};
  width: 100%;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {

    display:flex;
    flex-direction:column;



  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    order: 3;
    
    
    
    
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    max-width: 60%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 54%;
  }
`;

export const BasketItemGridPrices = styled.div<BasketItemGridPricesProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {


    display: grid;
    grid-template-columns: ${({ hasUpfrontPrice }) =>
      hasUpfrontPrice ? `repeat(2, minmax(${rem('170px')}, 1fr))` : `1fr`};

      

      
  }
  border-left: ${rem('1px')} solid #4a4d4e26;
  padding:0.5rem;
  margin-top:0.5rem;
  
 
  
  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    grid-column: 1 / -1;
    padding-bottom: ${rem('4px')};
    border-top: ${rem('1px')} solid #4a4d4e26;
    border-left: 0;
    flex-direction: column;

  

  }
`;






export const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem('10px')};
  
  border-radius: ${rem('6px')};
`;



export const BasketItemFlag = styled.div`
  background-color: ${({ theme }) => theme.color.primary1.default};
  position: absolute;
  top: 0;
  right: 0;
  font-family: 'VodafoneLight';
  font-size: ${rem('16px')};
  line-height: ${rem('20px')};
  padding: ${rem('5px')} ${rem('10px')};
  color: #ffffff;
  border-top-right-radius: ${rem('6px')};
  z-index: 0;
  &::before {
    content: '';
    width: ${rem('20px')};
    height: 100%;
    background-color: ${({ theme }) => theme.color.primary1.default};
    position: absolute;
    top: 0;
    left: ${rem('1px')};
    transform: skewX(30deg) translateX(-50%);
    z-index: -1;
  }
`;





export const AdditionalData = styled.div`
    width: 100%;
    position: relative;
    /* z-index: ; */

`



export default BasketItemStyles;
